import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { AButton, MModal } from 'ui'

declare global {
  export interface WindowEventMap {
    showExternalModal: CustomEvent
  }
}

export const CExternalModal = () => {
  const [show, setShow] = useState<boolean>(false)
  const [link, setLink] = useState<string>('')
  const { t } = useTranslation('common')

  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    window.addEventListener('showExternalModal', (event: CustomEvent) => {
      setLink(event.detail)
      setShow(true)
    })
  }, [])

  return (
    <MModal open={show} onClose={handleClose}>
      <>
        <div className="text-gray-700 font-light lg:text-2xl lg:leading-10">
          {t('do-you-wish-to-leave-this-site')}
        </div>
        <AButton
          type="a"
          className="mt-8 w-full"
          style="outline"
          color="teal"
          size="lg"
          href={link}
          target="_blank"
          onClick={handleClose}
        >
          <span>{t('yes-take-me-to-the-website')}</span>
        </AButton>
      </>
    </MModal>
  )
}
