import syncICL from 'sync/icl.json'
import syncEVO from 'sync/evo.json'
import { LegacyComponents } from '../settings-types'

const getLegacyComponents = (locale: string) => {
  let site = null
  let components = {}

  if (/^.+-evo$/.test(locale)) {
    // evo locale
    site = Object.keys(syncEVO.components).find((item) => {
      return item === locale.replace('-evo', '')
    })
    if (site) {
      type EvoComponentsKey = keyof typeof syncEVO.components
      components = syncEVO.components[site as EvoComponentsKey]
    }
  } else {
    // icl locale
    site = Object.keys(syncICL.components).find((item) => {
      return item === locale
    })
    if (site) {
      type IclComponentsKey = keyof typeof syncICL.components
      components = syncICL.components[site as IclComponentsKey]
    }
  }

  return components as LegacyComponents
}

export default getLegacyComponents
