import { CraftGlobals } from 'tsconfig/craft-types'
import {
  CCenteredCopy,
  CLegacyFooterEvo,
  CLegacyFooterIcl,
  CLegacyNavigationEvo,
  CLegacyNavigationIcl,
  CLegacySafetyInfo,
  CLocaleSwitcher,
  CReferencesModal,
  CTheFooter,
  CTheHeader,
  proximaFont,
} from 'ui'
import React, { useContext, useEffect } from 'react'
import { useSiteInfo } from 'shared'
import dynamic from "next/dynamic"
import { HeaderContext } from 'ui/context/header'
import { throttle } from 'lodash'
import { useRouter } from 'next/router'
import * as CONST from 'ui/constants/index'
import { AppContext } from '../context/app'
import { CExitIntentModal } from '../components/organisms/c-exit-intent-modal'
import { CExternalModal } from '../components/organisms/c-external-modal'
import getLegacyComponents from '../helpers/get-legacy-components'
import { CDisclaimer } from '../components/organisms/c-disclaimer'

type Props = {
  global: CraftGlobals
  children: React.ReactNode
}

const DynamicPreviewToast = dynamic(() =>
  import(
    /* webpackChunkName: "DynamicPreviewToast" */ 'ui'
    ).then((x) => x.CPreviewToast)
)

const DefaultLayout = ({ global, children }: Props) => {
  const siteInfo = useSiteInfo()
  const headerContext = useContext(HeaderContext)
  const appContext = useContext(AppContext)
  const router = useRouter()
  const isPreviewToastEnabled = Boolean(
    process.env.NEXT_PUBLIC_PREVIEW_MESSAGE_ENABLED
  )


  const legacyComponents = getLegacyComponents(
    `${siteInfo?.iso}${
      siteInfo?.layout === CONST.SITE_LAYOUT.EVO
        ? `-${CONST.SITE_LAYOUT.EVO}`
        : ''
    }` ?? ''
  )

  const handleWindowScroll = () => {
    headerContext?.setIsClear(
      window.scrollY === 0 && router.asPath === '/' && !siteInfo.isUS
    )
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    headerContext?.setIsClear(router.asPath === '/' && !siteInfo.isUS)
    window.addEventListener('scroll', handleWindowScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [appContext?.state?.query?.q])

  return (
    <main
      className={`${proximaFont.variable} ${
        siteInfo?.iso === 'ko-KR' && 's-word-break-keep-all'
      }`}
      dir={siteInfo?.dir}
    >
      {!siteInfo?.iframe &&
        siteInfo?.layout === CONST.SITE_LAYOUT.ICL &&
        (!legacyComponents.navigation || siteInfo.isNL) && (
          <CTheHeader
            isVisible={headerContext?.isVisible}
            isClear={headerContext?.isClear}
            countryCode={siteInfo.iso}
            backendUrl={process.env.NEXT_PUBLIC_DOCTORS_DISCOVERICL_BACKEND_URL}
            brand={siteInfo.branded}
            doctorsUrl="/"
            quizUrl={siteInfo.quizUrl}
            baseDomain={process.env.NEXT_PUBLIC_DOCTORS_DISCOVERICL_BASE_DOMAIN}
            discoveryUrl={siteInfo.discoverUrl}
            fullDomain={siteInfo.doctorUrl}
          />
        )}
      {!siteInfo?.iframe &&
        siteInfo?.layout === CONST.SITE_LAYOUT.ICL &&
        (legacyComponents.navigation && !siteInfo.isNL) && (
          <CLegacyNavigationIcl
            components={legacyComponents}
            transparent={headerContext?.isClear ?? false}
          />
        )}
      {!siteInfo?.iframe && siteInfo?.layout === CONST.SITE_LAYOUT.EVO && (
        <CLegacyNavigationEvo
          components={legacyComponents}
          transparent={headerContext?.isClear ?? false}
        />
      )}
      {children}
      {!siteInfo?.iframe &&
        siteInfo?.layout === CONST.SITE_LAYOUT.ICL &&
        (!legacyComponents.footer || siteInfo.isNL) && (
          <>
            <CLocaleSwitcher
              open={headerContext?.showLocaleSwitcher ?? false}
              onClose={() => headerContext?.setShowLocaleSwitcher(false)}
              domain={
                process.env.NEXT_PUBLIC_DOCTORS_DISCOVERICL_BASE_DOMAIN ?? ''
              }
            />
            <CReferencesModal field={global.referencesModal} />
            <CDisclaimer />
            {!siteInfo.isES && <CCenteredCopy field={global.centeredCopy} />}
            <CTheFooter
              hasNewsletter={siteInfo.iso === 'en-US'}
              hasExtraBelow={['de-DE'].includes(siteInfo.iso ?? '')}
              hasExtraInline={['ko-KR'].includes(siteInfo.iso ?? '')}
              hasStaarLink={['ja-JP'].includes(siteInfo.iso ?? '')}
              fullDomain={siteInfo.doctorUrl}
              discoveryUrl={siteInfo.discoverUrl}
            />
            {siteInfo.iso === 'en-US' && <CExitIntentModal />}
          </>
        )}
      {!siteInfo?.iframe &&
        siteInfo?.layout === CONST.SITE_LAYOUT.ICL &&
        (legacyComponents.footer && !siteInfo.isNL) && (
          <>
            <CLegacySafetyInfo components={legacyComponents} />
            <CLegacyFooterIcl components={legacyComponents} />
          </>
        )}
      {!siteInfo?.iframe &&
        siteInfo?.layout === CONST.SITE_LAYOUT.EVO &&
        (legacyComponents.footer && !siteInfo.isNL) && (
          <>
            <CLegacySafetyInfo components={legacyComponents} />
            <CLegacyFooterEvo components={legacyComponents} />
          </>
        )}
      <CExternalModal />
      {isPreviewToastEnabled && <DynamicPreviewToast />}
    </main>
  )
}

export default DefaultLayout
