import { useTranslation } from 'next-i18next'
import css from 'styled-jsx/css'

export const CDisclaimer = () => {
  const { t } = useTranslation('common')

  if (!t('disclaimer')) {
    return <></>
  }

  return (
    <section>
      <div className="w-full px-5 mx-auto max-w-[calc(936px+40px)] pt-14">
        <div
          className="c-disclaimer__copy mt-4 font-semibold text-primary-gray text-p-sm s-wysiwyg"
          dangerouslySetInnerHTML={{ __html: t('disclaimer') }}
        />
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-disclaimer__copy a {
    color: #10a8ba;
  }
`
